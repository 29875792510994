<template>
    <FinanceData/>
</template>

<script>
import { FinanceData } from 'shared'

export default {
    name: 'financeData',
    components: {
        FinanceData
    },
    data() {
        return {
            params: {
                name: '产业电商',
                a103: '建材家装'
            }
        }
    },
    created() {
        this.$store.dispatch('getFinancing', this.params)
    },
}
</script>